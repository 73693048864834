<template>
  <CDropdown
    v-if="total > 0"
    placement="bottom-end"
    :caret="false"
    in-nav
    class="c-header-nav-item mx-2"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div @click="reload()">
          <CIcon size="lg" name="cil-bell"/>
          <CBadge shape="pill" color="danger">{{total}}</CBadge>
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader
      tag="div"
      class="text-center bg-light"
    >
      <strong>{{total}}個のやることがあります。</strong>
    </CDropdownHeader>
    <CDropdownItem v-if="propose > 0">
      <CBadge shape="pill" color="danger" style="margin-right: 5px">{{propose}}</CBadge> お仕事提案への応答
    </CDropdownItem>
    <CDropdownItem v-if="offer > 0">
      <CBadge shape="pill" color="danger" style="margin-right: 5px">{{offer}}</CBadge> 依頼したお仕事の応募への応答
    </CDropdownItem>
    <CDropdownItem v-if="over_date > 0">
      <CBadge shape="pill" color="danger" style="margin-right: 5px">{{over_date}}</CBadge> 終了したお仕事を完了
    </CDropdownItem>
    <CDropdownItem v-if="question > 0">
      <CBadge shape="pill" color="danger" style="margin-right: 5px">{{question}}</CBadge> 依頼したお仕事の質問への回答
    </CDropdownItem>
  </CDropdown>
</template>
<script>
import Comm from '@/views/v1/lib/Comm.js'

export default {
  name: 'TheHeaderDropdownNotif',
  mixins: [Comm],/* ここでミックスインを追加 */
  computed: {
    offer: function () {
      if("offer" in this.$store.state.notifications){
        return this.$store.state.notifications.offer
      }
      return 0
    },
    over_date: function(){
      if("over_date" in this.$store.state.notifications){
        return this.$store.state.notifications.over_date
      }
      return 0
    },
    propose: function(){
      if("propose" in this.$store.state.notifications){
        return this.$store.state.notifications.propose
      }
      return 0
    },
    question: function(){
      if("question" in this.$store.state.notifications){
        return this.$store.state.notifications.question
      }
      return 0
    },
    total: function(){
      if("total" in this.$store.state.notifications){
        return this.$store.state.notifications.total
      }
      return 0
    }
  },
  methods: {
    reload(){
      this._get_notifications()
    }
  },
  data () {
    return {}
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>
