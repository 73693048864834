<template>
  <CFooter :fixed="false">
    <div class="mfs-auto">
      <span class="ml-1">&copy; <span style="font-style: italic">{{new Date().getFullYear()}} G&L Ltd.</span></span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
