<template>
  <CHeader>
    <CHeaderNav class="mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/">
          お仕事一覧
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav>
      <TheHeaderDropdownMssgs/>
      <TheHeaderDropdownNotif/>
      <TheHeaderDropdownAccnt class="px-3"/>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import TheHeaderDropdownNotif from './TheHeaderDropdownNotif'
import TheHeaderDropdownMssgs from './TheHeaderDropdownMssgs'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownNotif,
    TheHeaderDropdownAccnt,
    TheHeaderDropdownMssgs
  },
  methods: {
    moveTo(page_name) {
      this.$router.push({name: page_name})
    },
  }
}
</script>
