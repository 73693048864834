<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>

        <div class="c-avatar">
          <img
            :src="getAvatar"
            class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>アカウント</strong>
    </CDropdownHeader>
    <CDropdownItem>
      <CIcon style="margin-right: 5px" name="cil-user"/>
      プロフィール
    </CDropdownItem>
    <CDropdownItem
      v-if="$store.state.is_admin === true"
      @click="moveTo('ActivityLog')">
      <CIcon style="margin-right: 5px" name="cil-excerpt"/>
      アクティビティログ
    </CDropdownItem>
    <CDropdownItem
      v-if="$store.state.is_admin === true"
      @click="moveTo('Config')">
      <CIcon style="margin-right: 5px" name="cil-settings"/>
      設定
    </CDropdownItem>
    <CDropdownItem>
      <CIcon style="margin-right: 5px" v-if="$store.state.darkMode" name="cil-sun"/>
      <CIcon style="margin-right: 5px" v-else name="cil-moon"/>
        <button
          style="padding: 1px 0 1px 0"
          @click="() => $store.commit('toggle', 'darkMode')"
          class="c-header-nav-btn"
        >Dark or Light
      </button>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon style="margin-right: 5px" name="cil-address-book"/>
      <button
        style="padding: 1px 0 1px 0"
        @click="() => $store.commit('toggle', 'showMemberList')"
        class="c-header-nav-btn"
      >メンバ一覧表示
      </button>
    </CDropdownItem>
    <CDropdownDivider/>
    <CDropdownItem @click="_logout">
      <CIcon style="margin-right: 5px" name="cil-lock-locked"/>
      Logout
    </CDropdownItem>

  </CDropdown>

</template>

<script>
import Comm from '@/views/v1/lib/Comm.js'

export default {
  name: 'TheHeaderDropdownAccnt',
  mixins: [Comm],/* ここでミックスインを追加 */
  computed: {
    getAvatar(){
      return this._get_avatar(this.$store.state.user_id)
    },
    myImagePath:function () {
      return function () {
        const member_info = this.$store.state.your_family.filter(member => member.id === this.$store.state.user_id)
        return `${this.$store.state.image_path}NvDUdxLS/${this.$store.state.user_id}/0/${member_info[0]["avatar"]}`
        // return `${this.$store.state.image_path}${value}.jpg`
      }
    },
    image: {
      get() {
        return this.image_path
      },
    },
  },
  data() {
    return {
      itemsCount: 42
    }
  },
  methods: {
    moveTo(page_name) {
      this.$router.push({name: page_name})
    },
  }
}
</script>

<style scoped>
</style>
